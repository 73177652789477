import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import BlogPost from '../components/BlogPost'

export const BlogPageTemplate = ({
  data,
}) => (
  <div>
    <Helmet title={data.title} defer={false}/>
    <BlogPost data = {data}/>
  </div>
)

BlogPageTemplate.propTypes = {
  data: PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.object,
      content: PropTypes.string,
      imageArray: PropTypes.arrayOf( PropTypes.shape({
    discription: PropTypes.string,
    image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
  })),
      tagArray: PropTypes.arrayOf( PropTypes.shape({
    tag: PropTypes.string,
  })),
  })
}