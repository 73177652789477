import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { navigate } from "gatsby"
import Arrow from "../icons/arrowwithtail.svg"
import Printer from "../icons/Printer.svg"
import Share from "../icons/Share.svg"
import BlogSlideshow from './BlogSlideshow'
import ButtonMenu from './ButtonMenu'
import { convertDate, slugify } from '../../myutil'

const BlogPostContainer = styled.div`
position:relative;
width:90%;
max-width:900px;
margin:0 auto;

font-family:lato;
`
const TextPart = styled.div`
display:flex;
margin-top:60px;
& h1 {
    font-size:28px;
}
& > div:first-child {
    width:100%;
}
& > div:last-child {
    margin-top:30px;
}

`
const BackButton = styled.button`
display:flex;
flex-direction:row;
align-items:center;
background-color:transparent;
border:none;
margin:20px 0;

& p {
    color:var(--tmain);
    font-family:lato;
    font-size:18px;
    transition:color 0.4s ease;
    margin:0;
}

& svg {
    margin-top:4px;
    & > * {
        transition:fill 0.4s ease;
        fill:var(--tmain);
    }
    margin-right:10px;
    
}

&:hover {
    cursor:pointer;
    & p {
        color:var(--tgrey-dark);
    }
    & svg {
        & > * {
            fill:var(--tgrey-dark);
        }
    }
}
`

const Date = styled.div`
color:var(--tmain);
font-size:16px;
`
const Tags = styled.div`
position:relative;
display:flex;
flex-flow: row wrap;
& h2 {
    font-size: 14px;
    font-weight:400;
    padding: 6px 12px;
    background-color:#ddd;
    border-radius:100px;
    margin-right:10px;
}

`
const Line = styled.div`
position:relative;
width:100%;
height:1px;
background-color:#ddd;
margin-top:50px;
margin-bottom:20px;

`

const BlogPost = ({ data }) => {

    const debugNavigation = () => {
        if(navigate(-1)==undefined){
            navigate("/")
        }
        else{
            navigate(-1)
        }
    }

    return(
    <div>
        <Helmet>
            <title>KFV-ak.de | { data.title }</title>
            <meta name="keywords" content={ (Array.isArray(data.tagArray))?(data.tagArray.map((item,i) => item.tag)):("")}/>
        </Helmet>
        <BlogPostContainer>
            <BackButton onClick={debugNavigation}>
                <Arrow></Arrow>
                <p>zurück</p>
            </BackButton>
            
            { (Array.isArray(data.imageArray))?(
                <BlogSlideshow slides={data.imageArray}/> 
            ):(
                <div>No Images found</div>
            )
            }
            <TextPart>
            <div>
            <Date>{(typeof(data.date)=="string")?(convertDate(data.date)):(null)}</Date>
            <h1>{data.title}</h1>
            <Tags>
            {
                (Array.isArray(data.tagArray))?(
                    data.tagArray.map((item, i) => 
                    <h2 key={i}>{item.tag}</h2>
                    )
                ):(null)
            }
            </Tags>
            
            <ReactMarkdown>{data.content}</ReactMarkdown> 
            </div>
            <ButtonMenu data={{subject: data.title+" Title", link: (data.title === String) ? "kfv-ak.de"+slugify(data.title) : "/", text: "<p>"+data.title+"</p>"}}></ButtonMenu>
 
            
            </TextPart>
            <Line/>
        </BlogPostContainer>
    </div>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        slug: PropTypes.string,
        date: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        imageArray: PropTypes.arrayOf( PropTypes.shape({
            image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
            discription: PropTypes.string,
        })),
        tagArray: PropTypes.arrayOf( PropTypes.shape({
            tag: PropTypes.string,
        })),
    })
}

export default BlogPost;