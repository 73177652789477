exports.slugify = (title) => {
    let temp = title
    temp = temp.toLowerCase()
    temp = temp.replace(/ /g,"-")
    temp = temp.replace(/ä/g,"ae")
    temp = temp.replace(/ü/g,"ue")
    temp = temp.replace(/ö/g,"oe")
    temp = temp.replace(/ß/g,"s")
    temp = temp.replace(/[^0-9a-z-]/g,"")
  
    return "/"+temp
  }
exports.stripStatic = (path) => {
    let temp = path
    if(path.substring(0,7)=="/static")
        temp = temp.substring(7)
    return temp
}
exports.convertDate = (date) => {
  let temp = ""
  
  temp += date
  if(temp.length<10)
    return "[Date can not be displayed]"
  
  temp = temp.substring(0,10)
  let year = temp.substring(0,4)
  let month = temp.substring(5,7)
  let day = temp.substring(8,10)

  switch (month) {
    case "01":
        month = "Januar";
        break;
    case "02":
        temp = "Februar";
        break;
    case "03":
        month = "März";
        break;
    case "04":
        month = "April";
        break;
    case "05":
        month = "Mai";
        break;
    case "06":
        month = "Juni";
        break;
    case "07":
        month = "Juli";
        break;
    case "08":
        month = "August";
        break;
    case "09":
        month = "September";
        break;
    case "10":
        month = "Oktober";
        break;
    case "11":
        month = "November";
        break;
    case "12":
        month = "Dezember";
        break;
  }

  temp = day+" "+month+" "+year
  return temp
}