import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { BlogPageTemplate } from '../templates/blog-template'

const BlogPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <BlogPageTemplate
      data = { frontmatter }
    />
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default BlogPage

export const blogPageQuery = graphql`
query BlogTemplate(
  $title: String!
) {
  markdownRemark( frontmatter: { title: { eq: $title } } ) {
    frontmatter {
      title      
      date      
      content      
      imageArray {
        discription        
        image
          {
          childImageSharp {
            gatsbyImageData(
              width: 800,
            )}
          }
        
      }
      tagArray {
        tag        
      }
    }
  }
}

`